import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})
export class LeftsidebarComponent implements OnInit {

  @Input() sidebarType: string;

  name: string
  role: string

  constructor(public afs: AngularFirestore, public auth: AngularFireAuth, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.auth
      .onAuthStateChanged((user) => {
        let id = user.uid;
console.log(new Date().setDate(new Date().getDate() + 1))
        this.afs
          .collection("users", (ref) => ref.where("user", "==", id))
          .snapshotChanges()
          .subscribe((data) => {
            let log = data.map((e) => {
              return {
                id: e.payload.doc.id,
                firstname: e.payload.doc.data()["firstname"],
                lastname: e.payload.doc.data()["lastname"],
                system_role: e.payload.doc.data()["system_role"],
                //...e.payload.doc.data() as {},
              };
            });
            this.name = log[0].firstname + " " + log[0].lastname
            this.role = log[0].system_role
            console.log(this.name)
          });
      })
      .catch((e) => {
        
      });
  }

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {

    this.auth.signOut();
    this.router.navigateByUrl('account/login');

  }
}
