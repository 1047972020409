import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "../../../core/services/auth.service";

import { Notification } from "./topbar.model";

import { notificationItems } from "./data";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  notificationItems: Notification[];
  languages: Array<{
    id: number;
    flag?: string;
    name: string;
  }>;
  selectedLanguage: {
    id: number;
    flag?: string;
    name: string;
  };

  openMobileMenu: boolean;

  name: string;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    public afs: AngularFirestore,
    public auth: AngularFireAuth,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;

    this.auth
      .onAuthStateChanged((user) => {
        let id = user.uid;

        this.afs
          .collection("users", (ref) => ref.where("user", "==", id))
          .snapshotChanges()
          .subscribe((data) => {
            let log = data.map((e) => {
              return {
                id: e.payload.doc.id,
                firstname: e.payload.doc.data()["firstname"],
                //...e.payload.doc.data() as {},
              };
            });
            this.name = log[0].firstname
            console.log(this.name)
          });
      })
      .catch((e) => {
        
      });
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = notificationItems;
  }
}
