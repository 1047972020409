// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBLKQXO6MRpRZiQDxF5Mej4iuqwaUFd5Ok",
    authDomain: "dreamit-erp-dev.firebaseapp.com",
    databaseURL: "https://dreamit-erp-dev.firebaseio.com",
    projectId: "dreamit-erp-dev",
    storageBucket: "dreamit-erp-dev.appspot.com",
    messagingSenderId: "126622536486",
    appId: "1:126622536486:web:14282ba1b1b1d5da26a7e7",
    measurementId: "G-0MKDCN1JQG"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
